@tailwind base;
@tailwind components;
@tailwind utilities;

.cardHoverEffect{
    @apply bg-white rounded-lg shadow-lg hover:shadow-xl transition duration-300
}

main{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

th {
    font-weight: bold !important;
}

th,td {
    text-wrap: nowrap;
    text-align: center !important;
}

.mngm-text {
    color: #cdab34;
}
.mngm-bg {
    background-color: #082667!important
}